import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripLinesVertical } from '@fortawesome/free-solid-svg-icons';
import Sidebar from './Sidebar';
import { AuthProvider } from '../utils/AuthContext';

// Layout component
function Layout(props) {
  // Access the history object to navigate between routes
  const history = useHistory();
  // User state to hold user data
  const [user, setUser] = useState(null);
  // State to manage the visibility of the sidebar
  const [sidebarVisible, setSidebarVisible] = useState(true);
  // Function to toggle the visibility of the sidebar
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
   // Redirect to the login page if there is no authToken
  const token = localStorage.getItem('authToken')


  // useEffect to check authentication status on component mount
  useEffect(() => {
    if (!token) {
      history.push('/login'); // Change '/login' to the desired login route
      return null; // Render nothing for now, as the user will be redirected
    }
    // Extracting user data in local storage
    const userDataString = localStorage.getItem('userData');
    const userData = userDataString ? JSON.parse(userDataString) : null;
    setUser(userData);
  }, [token,history]);

  // Render the component
  return (
    <AuthProvider>
      <Container fluid>
        <Row>
          {/* Sidebar */}
          <Col xs={9} md={3} lg={2} className={`sidebar ${sidebarVisible ? 'active' : ''} `}>
            <Sidebar user={user}/>
            {/* Button to toggle the sidebar */}
            <a onClick={toggleSidebar} className="toggle-btn" href='#toogle'>
              <FontAwesomeIcon icon={faGripLinesVertical} size='1x' />
            </a>
          </Col>

          {/* Main content area */}
          <Col className={`main-content ${sidebarVisible ? 'active' : ''} mainBgColor vh-100`}>
            {/* Render child components */}
            {props.children}
          </Col>
        </Row>
      </Container>
    </AuthProvider>
  );
}

export default Layout;
