// SidebarItems.js
import IconHome from "../images/home.png";
import IconUser from "../images/user.png";
import IconCatalogue from "../images/catalogue.png";
import IconCommand from "../images/fi_truck.png";
import IconDelivery from "../images/fi_package.png";
import IconPromoCode from "../images/fi_percent.png";

const SidebarItems = [
  {
    name: "Dashboard",
    route: "/dashboard",
    icon: IconHome,
  },
  {
    name: "Utilisateurs",
    route: "/users",
    icon: IconUser,
  },
  {
    name: "Catalogue",
    route: "/",
    icon: IconCatalogue,
    subcategories: [
      {
        name: "Produits",
        route: "/products",
      },
      {
        name: "Marques",
        route: "/brands",
      },
      {
        name: "Catégories",
        route: "/Categories",
      },
      // Add more subcategories if needed
    ],
  },
  {
    name: "Commandes",
    route: "/orders",
    icon: IconCommand,
  },
  {
    name: "Livraisons",
    route: "/",
    icon: IconDelivery,
    subcategories: [
      {
        name: "Centres de livraison",
        route: "/deliveries",
      },
      {
        name: "Tours de livraison",
        route: "/tour",
      },     
    ],
  },
  {
    name: "Codes promo",
    route: "/CodePromo",
    icon: IconPromoCode,
  },
];

export default SidebarItems;
