import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Button, Col, Row, Card, Image } from "react-bootstrap";

const ImageUploder = ({ setFils, errors = {}, fils }) => {
  const [localPaths, setLocalPaths] = useState([]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setFils([...fils, ...files]);
  };
  const handleRemoveImage = (index) => {
    const newImages = fils.filter((_, i) => i !== index);
    // setLocalImages(newImages);
    setFils(newImages);
  };

  useEffect(() => {
    if (Array.isArray(fils)) {
      const newLocalPaths = fils.map((image) =>
        URL.createObjectURL(image.file || image)
      );
      setLocalPaths(newLocalPaths);

      // Clean up URLs when component unmounts
      return () => {
        newLocalPaths.forEach((path) => URL.revokeObjectURL(path));
      };
    }
  }, [fils]);

  return (
    <Card className="mb-3">
      <Card.Body>
        <Form.Group id="formImages" className="pb-3">
          <Form.Label>Images</Form.Label>
          <input
            type="file"
            multiple
            className="form-control"
            onChange={handleImageChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors.images}
          </Form.Control.Feedback>
        </Form.Group>
        <Row>
          {Array.isArray(fils) &&
            fils.map((image, index) => (
              <Col key={index} md={3} className="mb-3">
                <Card>
                  <Card.Body>
                    <img src={localPaths[index]} />
                    <Button
                      variant="danger"
                      size="sm"
                      className="mt-2"
                      onClick={() => handleRemoveImage(index)}
                    >
                      Supprimer
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default ImageUploder;
