import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Card, Row, Col, Form } from "react-bootstrap";
import IconInfo from "../../images/info_outline_24px.png";
import { fetchKpiData } from "./DashboardService";
import { formatAllType } from '../../utilities';
// Définition du composant fonctionnel
function Dashboard() {
  // Initialisation des hooks nécessaires
  const [tauxChurn, setTaux_Churn] = useState("");
  const [codePromotionnel, setCodePromotionnel] = useState("");
  const [chiffreAffair, setChiffreAffair] = useState("");
  const [nombreAarticle, setNombreArticle] = useState("");
  const [topProduits, setTopProduits] = useState("");
  const [topClients, setTopClients] = useState([]);
  const [paniersAbandonnes, setPaniersAbandonnes] = useState("");
  const [panierMoyen, setPanierMoyen] = useState("");
  const [frequenceAchat, setFrequenceAchat] = useState("");
  const [nps, setNps] = useState("");
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  //Initialisation de la section periode
  const [sectionPeriod, setSectionPeriod] = useState({
    start_date: getDefaultStartDate(),
    end_date: getDefaultEndDate(),
  });

  const cardData = [
    { title: "Taux Churn", icon: IconInfo, content: tauxChurn, lg: "3" },
    {
      title: "Panier abandonnés",
      icon: IconInfo,
      content: paniersAbandonnes,
      lg: "3",
    },
    { title: "Panier moyen", icon: IconInfo, content: panierMoyen, lg: "3" },
    {
      title: "Fréquence d’achat",
      icon: IconInfo,
      content: frequenceAchat,
      lg: "3",
    },
    { title: "Top produits", icon: IconInfo, content: topProduits, lg: "6" },
    { title: "Top clients", icon: IconInfo, content: topClients },
    {
      title: "Code promotionnel utilisés",
      icon: IconInfo,
      content: codePromotionnel,
      lg: "3",
    },
    {
      title: "Nombre de transaction",
      icon: IconInfo,
      content: nps,
      lg: "3",
    },
    {
      title: "chiffre d'affaire",
      icon: IconInfo,
      content: chiffreAffair,
      lg: "3",
    },
    {
      title: "Nombre d’article tickets",
      icon: IconInfo,
      content: nombreAarticle,
      lg: "3",
    },
  ];
  function getDefaultStartDate() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return `${currentYear}-01-01`; // January 1st of the current year
  }

//Fonction pour définir date de déut et date fin de la tranche date
  function getDefaultEndDate() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = (currentDate.getMonth() + 1)
      .toString()
      .padStart(2, "0"); // Adding zero padding if necessary
    const currentDay = currentDate.getDate().toString().padStart(2, "0"); // Adding zero padding if necessary
    return `${currentYear}-${currentMonth}-${currentDay}`; // Current date
  }
  //Fonction pour afficher les KPI
  const fetchData = async () => {
    try {

      const response = await fetchKpiData(sectionPeriod);
      setTaux_Churn(formatAllType(response["Taux Churn"]));
      setCodePromotionnel(response["code_promo_utiliser"]);
      setChiffreAffair(formatAllType(response["chiffre_affair"]));
      setNombreArticle(response["average_panier_items_per_commande"]);
      setNps(response["NPS"]);
      setTopProduits(response["top_products"]);
      setTopClients(response["top_clients"]);
      setPaniersAbandonnes(response["abandoned_panier_count"]);
      setPanierMoyen(formatAllType(response["average_cart_value"]));
      setFrequenceAchat(formatAllType(response["order_percentage"]));
      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des données KPI:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [sectionPeriod]);

  useEffect(() => {
    // Récupération des données de l'utilisateur depuis le stockage local
    const userData = JSON.parse(localStorage.getItem("userData"));

    // Définir les données de l'utilisateur dans l'état
    if (userData) {
      setUser(userData);
    }
  }, []);
  // Fonction pour mettre à jour la date de début
  const handleStartDateChange = (event) => {
    setSectionPeriod({ ...sectionPeriod, start_date: event.target.value });
  };
  // Fonction pour mettre à jour la date fin
  const handleEndDateChange = (event) => {
    setSectionPeriod({ ...sectionPeriod, end_date: event.target.value });
  };
  // Rendu du composant
  return (
    <Container>
      <p className="h1 pb-4 pt-4">
        Bonjour {user ? `${user.prenom} ${user.nom}` : "Administrateur"}
      </p>

      <p className="lead">Sélectionner une période :</p>
      <Row>
        <Col md={3}>
          <Form.Label className="stateDate">Date début : </Form.Label>
          <input
            type="date"
            id="datetime_start"
            name="datetime_start"
            className="form-control"
            value={sectionPeriod.start_date}
            onChange={handleStartDateChange}
          />
        </Col>
        <Col md={3}>
          <Form.Label className="stateDate">Date fin :</Form.Label>
          <input
            type="date"
            id="datetime_end"
            name="datetime_end"
            className="form-control"
            value={sectionPeriod.end_date}
            onChange={handleEndDateChange}
          />
        </Col>
      </Row>

      {loading ? (
        <p className="lead">Chargement...</p>
      ) : (
        <Row style={{ paddingTop: "20px" }}>
          {cardData.map((card, index) => (
            <Col key={index} lg={card.lg} md={6} sm={12} className="mb-4">
              <Card className="border-0">
                <Card.Body>
                  <Card.Title className="d-flex justify-content-between align-items-center">
                    <span>{card.title}</span>
                  </Card.Title>
                  <Card.Text>
                    <div>
                      {card.title === "Top produits" &&
                      Array.isArray(card.content) ? (
                        <ul>
                          {card.content.slice(0, 3).map((product, index) => (
                            <li key={index}>
                              <h4 className="fw-bold">
                                {product.product_name}
                              </h4>
                            </li>
                          ))}
                        </ul>
                      ) : card.title === "Top clients" &&
                        Array.isArray(card.content) ? (
                        <ul>
                          {card.content.slice(0, 3).map((client, index) => (
                            <li key={index} className="font-weight-bold">
                              <a
                                href={`/user/show/${client.user.id}`}
                                className="fw-bold link-style"
                              >
                                {client.profil_user?.nom}{" "}
                                {client.profil_user?.prenom}
                              </a>
                              <p>Email: {client.user?.email} </p>{" "}
                              {client.profil_user?.societe && (
                                <p>Société: {client.profil_user?.societe} </p>
                              )}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="fw-bold"> {card.content}</p>
                      )}
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
}
// Exportation du composant par défaut
export default Dashboard;
