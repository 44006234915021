import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import NotFound from "./pages/NotFound";
import Layout from "./components/Layout";
//Authentication route
import Log_in from "./pages/Authentication/LoginScreen";
import resetPassword from "./pages/Authentication/ResetPasswordScreen"
import changePassword from "./pages/Authentication/ChangePasswordScreen";

//User route
import ListScreen from "./pages/users/ListScreen";
import EditScreen from "./pages/users/EditScreen"
import ShowScreen from "./pages/users/ShowScreen"
//Products route
import Product_ListScreen from "./pages/Proucts/ListScreen";
import Product_EditScreen from "./pages/Proucts/EditScreen";
import Product_AddScreen from "./pages/Proucts/AddScreen";
//Categories route
import Categories_ListScreen from "./pages/Categories/ListScreen";
//Brands route
import Brands_ListScreen from "./pages/Brands/ListScreen";
//Deliveries route
import Deliveries_ListScreen from "./pages/Deliveries/ListScreen";
import zone_showScreen from "./pages/Deliveries/showScreen"
//Orders route
import Orders_ListScreen from "./pages/orders/ListScreen";
import Orders_showScreen from "./pages/orders/ShowScreen"
//Deliveries route
import DeliveryTower_ListScreen from "./pages/DeliveryTower/ListScreen";
// code Promo route
import CodePromo_ListScreen from "./pages/CodePromo/ListScreen"
//  utilisateur
import User_AddScreen  from "./pages/users/AddScreen"

function Routes() {
    return (
        <BrowserRouter>
         <Switch>
            
            <Route path="/login" component={Log_in} /> {/* Login route */}       
            <Route path="/reinitialiser_mot_de_passe" component={resetPassword} /> {/* Reset Password Route */}
            <Route path="/changer_mot_de_passe"  component={changePassword} /> {/* Change Password Route */}
      
            <Route render={(props)=>(
                <Layout {...props}>
                    <Switch>
                        {/* DASHBOARD */}
                        <Route path="/" exact component={Dashboard}/>
                        <Route path="/dashboard" exact component={Dashboard}/>
                        {/* USERS */}
                        <Route path="/users" component={ListScreen}/>
                        <Route path="/user/edit/:id" component={EditScreen} />
                        <Route path="/user/show/:id" component={ShowScreen} />
                        <Route path="/user/add" component={User_AddScreen}  />
                        {/* PRODUCTS */}
                        <Route path="/products" component={Product_ListScreen}/>
                        <Route path="/product/edit/:productId" component={Product_EditScreen}/>
                        <Route path="/product/add/" component={Product_AddScreen}/>
                        {/* CATEGORIES */}
                        <Route path="/categories" component={Categories_ListScreen}/>
                        {/* BRANDS */}
                        <Route path="/brands" component={Brands_ListScreen}/>
                        {/* DELIVERIES */}
                        <Route path="/deliveries" component={Deliveries_ListScreen}/>
                        <Route path="/zone/show/:id" component={zone_showScreen}/>
                        {/* ORDERS */}
                        <Route path="/orders" component={Orders_ListScreen}/>
                        <Route path="/order/show/:orderId" component={Orders_showScreen}/>
                        {/* DELIVERY TOWER */}
                        <Route path="/tour" component={DeliveryTower_ListScreen}/>
                        {/* DELIVERY TOWER */}
                        <Route path="/CodePromo" component={CodePromo_ListScreen}/>
                        {/* 404 NOT FOUND */}
                        <Route component={NotFound}/>
                    </Switch>
                </Layout>
            )}/>
            </Switch>
        </BrowserRouter>
    )
}


export default Routes;
