// AuthContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "./ApiConfig"; // Import the configured Axios instance

// Create a context to manage authentication state
export const AuthContext = createContext();

// AuthProvider component responsible for managing authentication state
export const AuthProvider = ({ children }) => {
  const history = useHistory();
  const [authToken, setAuthToken] = useState(null);

  // Function to handle user login
  const login = async (email, password) => {
    try {
      // Make a login request to the API
      const response = await api.post("/login", {
        email,
        password,
      });

      // Extract the token from the API response and update the authToken
      const { token } = response.data.data;
      setAuthToken(token);
      // Store the authToken in localStorage for persistent login
      localStorage.setItem("authToken", token);

      return response; // Return the entire response object
    } catch (error) {
      console.error("Login failed :", error.message);
      throw error;
    }
  };

  // Function to handle user logout
  const logout = () => {
    setAuthToken(null); // Clear the authToken
    localStorage.removeItem("authToken"); // Remove authToken from localStorage
    localStorage.removeItem("userData"); 
    localStorage.clear();
    history.push("/login"); // Redirect to the login page
    const tokenafterLogout = localStorage.getItem("authToken");
  };

  //fonction pour vérifier si le token est valide
  const Verify = () => {
    const storedTokens = localStorage.getItem("authToken");
    //setAuthToken(storedTokens);
    if (storedTokens) {
      setAuthToken(storedTokens); // Set authToken if storedToken exists
    } else {
      setAuthToken(null); // Clear the authToken
      localStorage.removeItem("authToken"); // Remove authToken from localStorage
    }
  };
  // useEffect to initialize the authToken from localStorage on component mount
  useEffect(() => {
    Verify();
  }, [authToken]); // Re-run the effect if authToken changes

  // Provide the AuthContext.Provider with the authentication context value
  return (
    <AuthContext.Provider value={{ authToken, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to access the authentication context
export const useAuth = () => {
  const context = useContext(AuthContext);

  // Ensure that useAuth is used within an AuthProvider
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};
