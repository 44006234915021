 ///////////////////////////////// Ce fichier pour les fonction qui se répète par tous/////////////////////////////////////////
 
 // Fonction pour formater le nombre avec des séparateurs de milliers et arrondir à 2 chiffres après la virgule
 export const formatAllType = (value) => {
  let valueConvert = Number(value);
  return new Intl.NumberFormat('fr-FR', {  
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }).format(valueConvert);  
};



