// dataService.js
import api from '../../utils/ApiConfig';

// Fetch all categories form database ************************************************************
const fetchBrandsData = async (nbr,termSearch) => {
  try {
    const response = await api.get(`/marquesBo?per_page=50&page=${nbr}${termSearch}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};



const fetchBrandsDatano = async (nbr) => {
  try {
    const response = await api.get(`/marques`);
    return response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    throw error;
  }
};


// Update brand status  ************************************************************
const updateBrandStatus = async (BrandId) => {
  try {
    const response = await api.delete(`marques/delete/${BrandId}}`);

    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated brand data
      return response;
    } else {
      throw new Error(`Failed to update brand status. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating brand status:', error);
    throw error;
  }
};
// Update brand data  ************************************************************
const updateBrandData = async (brandData) => {
  try {
    const response = await api.post(`/marques/update`, brandData);
    // Check if the response status is 200 (OK)
    if (response.status === 200) {
      // Check if the response data is valid and return the updated brand data
      return response;
    } else {
      throw new Error(`Failed to update brand data. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating brand data:', error);
    throw error;
  }
};
// Save brand data  ************************************************************
const saveBrandData = async (brandData) => {
  try {
    const response = await api.post(`/marques/create`, brandData);
    // Check if the response status is 200 (OK)
    if (response.status === 201) {
      // Check if the response data is valid and return the updated brand data
      return response;
    } else {
      throw new Error(`Failed to update brand data. Statuss: ${response.status}`);
    }
  } catch (error) {
    console.error('Error updating brand datax:', error);
    throw error;
  }
};

// Export methods ************************************************************
export { fetchBrandsData, updateBrandStatus, updateBrandData, saveBrandData, fetchBrandsDatano };