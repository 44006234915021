// LoadingModal.js
import React from 'react';
import { Modal, Spinner } from 'react-bootstrap';

const LoadingModal = ({ show, onClose }) => {
  return (
    <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
      <Modal.Body className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Chargement...</span>
        </Spinner>
        <p className="mt-2">Chargement..</p>
      </Modal.Body>
    </Modal>
  );
};

export default LoadingModal;
