import { useState } from "react";
import EyeClosed from "../../images/fi_eye-off.svg";
import Eye from "../../images/fi_eye.svg";
import { UpdatePassword } from "./DataService";
import { useHistory, useLocation } from "react-router-dom";
import Info from "../../images/fi_info.svg";


const PasswordChang = () => {
  const location = useLocation();
  const { state } = location;
  const email = state && state.state.email;
  const code = state && state.state.code;
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const history = useHistory();
  const handleToggle = () => {
    setShowPassword(!showPassword);
  };

  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    return regex.test(password);
  };

  const changePass = async () => {
    if (!validatePassword(password)) {
      setError(
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial."
      );
      return;
    }

    const data = { email, password, code };

    try {
      const res = await UpdatePassword(data);
      setSuccess("Votre mot de passe a été mis à jour avec succès.");
      history.push("/login");
    } catch (err) {
      console.error("Error updating password:", err);
      setError(
        "Erreur lors de la mise à jour du mot de passe. Veuillez réessayer."
      );
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center mainBgColor">
      <div className="container-sm blurBG p-4 rounded" style={{ width: 400 }}>
        <div className="row pt-60">
          <div className=" p-24 reset-form">
            <h4 className="text-left mainColor style-h5">
              Crée ton nouveau mot de passe
            </h4>

            <div className="password-input-container form-floating mb-3 flex-grow-1">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setError(""); // Clear the error message when the password changes
                  setSuccess(""); // Clear success message when the password changes
                }}
                className={`form-control  ${error ? "danger-input" : ""}`}
                id="password"
                placeholder=""
              />
              <label htmlFor="password" className="code-label">
                Mot de passe
              </label>
              <div className="password-toggle-icon">
                <img
                  src={showPassword ? EyeClosed : Eye}
                  className="password-icon"
                  alt="password toggle"
                  onClick={handleToggle}
                />
              </div>
            </div>
            {error && <div className="text-danger">{error}</div>}
            {success && <div className="text-success">{success}</div>}
            <div className="mt-8 d-flex align-items-start info text-code-confirm ">
              <img src={Info} alt="note icon" className="mr-12" />
              <p className="mb-3 colorBlue">
                Le mot de passe doit contenir au moins : 8 caractères, une
                majuscule et un caractère spécial
              </p>
            </div>
            <button
              className="btn btn-primary btn-lg w-100"
              onClick={changePass}
            >
              Je me connecte
            </button>
          </div>
          <div className="col-md-4"></div>
        </div>
        {/* Optionally include a link or button to navigate manually */}
        {success && (
          <div className="text-center mt-4">
            <p>
              <a href="/login">Cliquez ici pour vous connecter</a>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PasswordChang;
