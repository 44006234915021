import React, { useMemo, useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import { Row, Col, Button, Badge } from 'react-bootstrap';
import ClickBack from '../../components/buttons/ClickBack';
import DynamicTable from '../../components/DynamicTable';
import moment from 'moment';
import { changeStatus, deleteProduct, fetchProductData, updateProductStatus } from './DataService';
import IconTrash from '../../images/trash.png';
import IconEdit from '../../images/edit.png';
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import OkModal from '../../components/modal/OkModal';
import { formatAllType } from '../../utilities';


function ListScreen(props) {
  const [ProductData, setProductData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [nbr, setNbr] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showOkModal, setShowOkModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [selectedProductId, setSelectedProductId] = useState(null);

//Function to get all product
  const fetchData = async () => {
    try {
      setLoading(true);
      let data = await fetchProductData(nbr, searchTerm);
      setProductData(data.data.products);
    } catch (error) {
      console.error('Error fetching Product data:', error);
    } finally {
      setLoading(false);
    }
  };
//Function to delete product
  const handleDelete = async () => {
    try {
      const response = await changeStatus(selectedProductId);
      if (response.status === 200) {
        setShowOkModal(true);
        fetchData(); // Refresh data after deletion
      } else {
        throw new Error(`Échec lors de la suppression du produit: ${response.status}`);
      }
    } catch (error) {
      console.error("Erreur lors de la suppression du produit :", error);
    } finally {
      setShowConfirmationModal(false);
    }
  };
//to confirm modal
  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
//to close modal
  const handleOkModalClose = () => {
    setShowOkModal(false);
  };
//to confirm delete modal
  const handleDeleteClick = (productId) => {
    setSelectedProductId(productId);
    setActionType("delete");
    setShowConfirmationModal(true);
  };

  useEffect(() => {
    fetchData(nbr);
  }, [nbr, searchTerm]);

  const columns = useMemo(
    () => [
      {
        Header: 'Titre produit', accessor: 'titre',
        Cell: ({ value }) => (
          <div className='LongText' title={value}>
            {value}
          </div>
        ),
      },
      { Header: 'Catégorie', accessor: 'product_category.name' },
      { Header: 'Référence', accessor: 'reference' },
      { Header: 'Prix HT', accessor: 'ht' ,
        Cell:({value}) => formatAllType(value)
      },
      { Header: 'Marque', accessor: 'product_marque.name' },
      {
        Header: 'Date de création', accessor: 'created_at', Cell: ({ value }) => (<p>{moment(value).format('DD/MM/YYYY')}</p>),
      },
      {
        Header: 'Statut',
        accessor: 'status',
        Cell: ({ value }) => (
          <Badge className={value === "1" ? 'bg-success' : 'bg-danger'}>
            {value === "1" ? 'Actif' : 'Inactif'}
          </Badge>
        ),
      },
      {
        Header: 'Actions',
        accessor: 'id',
        Cell: ({ value }) => (
          <div>
            <a href={`product/edit/${value}`} className='p-2'>
              <img src={IconEdit} width='20px' alt='Modifier' />
            </a>
            <a
              href="#"
              className="p-2"
              onClick={() => handleDeleteClick(value)}
            >
              <img src={IconTrash} width="20px" alt="Supprimer" />
            </a>
          </div>
        ),
      },
    ],
    []
  );

  if (!ProductData) {
    return <Container><p>Chargement...</p></Container>;
  }

  return (
    <Container>
      <div className='ProductList'>
        <p className='h6'>Accueil / Produits</p>
        <Row className='pt-4 pb-2'>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <ClickBack />
            <h1 className='p-1'>Produits</h1>
          </Col>
          <Col xs lg='2' className='d-flex justify-content-end align-items-center'>
            <a className='btn btn-primary' href='/product/add'>Ajouter produit</a>
          </Col>
        </Row>
        {loading ? (
          <p className='lead'>Chargement...</p>
        ) : (
          <DynamicTable
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            columns={columns}
            data={ProductData?.data}
            link={ProductData?.links}
            next_page_url={ProductData?.next_page_url}
            prev_page_url={ProductData?.prev_page_url}
            last_page={ProductData?.last_page}
            setNbr={setNbr}
            nbr={nbr}
            total={ProductData?.total}
            name="product"
          />
        )}
      </div>
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleDelete}
        actionType={actionType}
      />
      <OkModal
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
      />
    </Container>
  );
}

export default ListScreen;
