import axios from "axios";
import { jwtDecode } from "jwt-decode";



// Additional export for the API PDF URL
//const urlPdf = "https://recette.api.marion-materiaux.com"; // Recette 
const urlPdf = "https://api.marion-materiaux.com"; // Prod

const instance = axios.create({
//baseURL: "https://recette.api.marion-materiaux.com/api", // Recette  
baseURL: 'https://api.marion-materiaux.com/api', // Prod

});

const authToken = localStorage.getItem("authToken");

function checkTokenAndRedirect(authToken) {
  if (!isTokenValid(authToken)) {
    if (window.location.pathname !== "/login" && window.location.pathname !== "/reinitialiser_mot_de_passe" && window.location.pathname !== "/changer_mot_de_passe") {
      window.location.href = "/login";
    }
  }
}

function isTokenValid(token) {
  try {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp < Date.now() / 1000) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}

checkTokenAndRedirect(authToken);

instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem("authToken");
    if (authToken) {
      config.headers.Authorization = `Bearer ${authToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);




export { urlPdf };

export default instance;
