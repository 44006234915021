import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Container, Row, Col, Card, Button, Alert, Badge } from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import { fetchDeliveryById } from "./DataService";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

function ShowScreen() {
  const { id } = useParams();
  const history = useHistory();
  const [delivery, setDelivery] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchDeliveryData = async (id) => {
    try {
      setLoading(true);
      const data = await fetchDeliveryById(id);
      setDelivery(data);
    } catch (error) {
      setError('Erreur lors de la récupération des données de la zone de livraison.');
      console.error('Erreur lors de la récupération des données de la zone de livraison :', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDeliveryData(id);
  }, [id]);

  if (loading) {
    return <Container><p>Chargement...</p></Container>;
  }

  if (error) {
    return <Container><Alert variant="danger">{error}</Alert></Container>;
  }

  if (!delivery) {
    return <Container><p>Aucune donnée disponible</p></Container>;
  }

  const extractFileName = (path) => {
    const match = path.match(/(\d+)\.kml$/);
    return match ? match[1] : 'Nom de fichier non trouvé';
  };

  return (
    <Container>
      <div className='DeliveryDetails'>
        <p className='h6'>Accueil / Zone de Livraison / Détails</p>
        <Row className='pt-4 pb-2'>
          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <ClickBack editPage={true} />
            <h1 className='p-1'>Détails de la Zone de Livraison</h1>
          </Col>
        </Row>

        <Card className="mb-3">
          <Card.Body>
            <Card.Title className="pb-3">Détail Zone de Livraison</Card.Title>
            <Col className="pb-3"> 
              <p>
                <strong>Date de création: </strong>
                {format(new Date(delivery.created_at), "dd-MM-yyyy HH:mm", { locale: fr })}
              </p>
            </Col>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>ID: </strong>
                  {delivery.id}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>État: </strong>
                
                    {delivery.status}
               
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Titre: </strong>
                  {delivery.name}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Nom fichier KML: </strong>
                  {extractFileName(delivery.path)}
                </p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </Container>
  );
}

export default ShowScreen;
