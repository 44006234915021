import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";



import moment from "moment";
import { GetOneUser } from "./dataService";

function ShowScreen(props) {
  const [userData, setUserData] = useState(null);
  const { id } = useParams();


  const fetchUserData = async (id) => {
    try {
      const user = await GetOneUser(id);
      setUserData(user);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  useEffect(() => {
    fetchUserData(id);
  }, [id]);

  if (!userData) {
    return (
      <Container>
        <p>Loading...</p>
      </Container>
    );
  }
  const defaultAddress = userData.user_adresses?.find(
    (addr) => addr.default === 1
  );
  return (
    <Container>
      <div className="userList">
        <p className="h6">
          Home / Users / {userData.nom} {userData.prenom}
        </p>

        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">
              {userData.nom} {userData.prenom}
            </h1>
          </Col>
        </Row>

        <Card className="mb-3">
          <Card.Body>
            <Card.Title className="pb-3">Détail profil Utilisateur</Card.Title>
            <Col className="pb-3">
              <p>
                <strong>Date de création: </strong>
                {moment(userData.created_at).format("DD/MM/YYYY , HH:MM")}
              </p>
            </Col>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>ID: </strong>
                  {userData.id}
                </p>
              </Col>

              <Col>
                <p>
                  <strong>Etat: </strong>
                  {userData.status === 1 ? "Active" : "Inactive"}
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Prénom: </strong>
                  {userData.prenom}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Nom: </strong>
                  {userData.nom}
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Numéro de téléphone: </strong>
                  {userData.user_profil?.telephone || "-"}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Email: </strong>
                  {userData.email}
                </p>
              </Col>
            </Row>

            <Row className="pb-3">
              <Col>
                <p>
                  <strong>Nom de l'adresse: </strong>
                  {userData.user_adresses?.[0]?.name || "-"}
                </p>
              </Col>
              <Col>
                <p>
                  <strong>Adresse: </strong>
                  {userData.user_adresses?.[0]?.adresse || "-"}
                </p>
              </Col>
            </Row>
            <Row className="pb-3">
              {userData.role === "2" && (
                <>
                  <Row className="pb-3">
                    {userData.user_profil?.siret && (
                      <Col>
                        <p>
                          <strong>Numéro de SIRET: </strong>
                          {userData.user_profil.siret}
                        </p>
                      </Col>
                    )}
                    {userData.user_profil?.tva && (
                      <Col>
                        <p>
                          <strong>Numéro de TVA: </strong>
                          {userData.user_profil.tva}
                        </p>
                      </Col>
                    )}
                  </Row>
                  <Row className="pb-3">
                    {userData.user_profil?.societe && (
                      <Col>
                        <p>
                          <strong>Société: </strong>
                          {userData.user_profil.societe}
                        </p>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              <Col>
                {userData.commentaire && (
                  <p>
                    <strong>Commentaire: </strong>
                    {userData.commentaire}
                  </p>
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* Additional sections or details can be added here */}
      </div>
    </Container>
  );
}

export default ShowScreen;
