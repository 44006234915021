import React from 'react';
import ReactDOM from "react-dom/client";
import Routes from "./routes";
import { AuthProvider } from './utils/AuthContext'; // Import the AuthProvider

import 'bootstrap/dist/css/bootstrap.css';
import './style/main.css';
import './index.css';


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Routes />
    </AuthProvider>
  </React.StrictMode>
);
