import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Card,
  Alert,
} from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import { GetOneUser, saveUserData } from "./dataService";
import moment from "moment";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import ConfirmationModal from "../../components/modal/ConfirmationModal";
import OkModal from "../../components/modal/OkModal";

function EditScreen() {
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [userGender, setUserGender] = useState("");
  const [address, setAddress] = useState("");
  const [latLong, setLatLong] = useState({ lat: undefined, lng: undefined });
  const [status, setStatus] = useState("");
  const [siret, setSiret] = useState("");
  const [societe, setSociete] = useState("");
  const [tva, setTva] = useState("");
  const [showOkModal, setShowOkModal] = useState(false); // New state for OkModal
  const [userRole, setUserRole] = useState("");
  const [currentPassword, setCurrentPassword] = useState(""); // New state for current password
  const [newPassword, setNewPassword] = useState(""); // New state for new password
  const [passwordError, setPasswordError] = useState(""); // State for password error message
  const [nouveauPasswordError, setNouveauPasswordError] = useState("")// State for new password error message
  const [userConnected, setUserConnected] = useState("");
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    fetchData(id);
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData && userData.id) {
      setUserConnected(userData.id);
    }
  }, [id]);
  //Fonction pour la validation du Format mot de passe
  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
    return regex.test(password);
  };
  const fetchData = async (id) => {
    setLoading(true);
    try {
      const user = await GetOneUser(id);
      setUserRole(user?.role || "");
      setUserData(user);
      setFirstName(user?.prenom || "");
      setLastName(user?.nom || "");
      setPhoneNumber(user?.user_profil?.telephone || "");
      setEmail(user?.email || "");
      setUserGender(user?.user_adresses?.[0]?.gender || "");
      setAddress(user?.user_adresses?.[0]?.adresse || "");
      setLatLong({
        lat: user?.user_adresses?.[0]?.latitude,
        lng: user?.user_adresses?.[0]?.longitude,
      });
      setStatus(user?.status === 1 ? "Active" : "Inactive");
      if (user?.role === "2") {
        setSiret(user?.user_profil?.siret || "");
        setSociete(user?.user_profil?.societe || "");
        setTva(user?.user_profil?.tva || "");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveClick = () => {
    if (currentPassword && !validatePassword(newPassword)) {
      setNouveauPasswordError(
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial."
      );
      return;
    }
    setActionType("save");
    setShowConfirmationModal(true);
  };

  // Function to handle OkModal close
  const handleOkModalClose = () => {
    setShowOkModal(false);
    // Optionally, you can perform additional actions after OkModal is closed
  };

  // Function to handle modal exit
  const handleCloseConfirmationModal = () => {
    // If the user cancels, simply close the modal
    setShowConfirmationModal(false);
  };

  const handleSaveConfirm = async () => {
    const updatedUserData = {
      nom: lastName,
      prenom: firstName,
      email: email,
      telephone: phoneNumber,
      status: status === "Active" ? 1 : 0,
      address: {
        name: "-",
        gender: userGender,
        adresse: address,
        latitude:
          latLong.lat !== undefined
            ? latLong.lat
            : userData.user_adresses?.[0]?.latitude || null,
        longitude:
          latLong.lng !== undefined
            ? latLong.lng
            : userData.user_adresses?.[0]?.longitude || null,
        type: "2",
      },
      id: id,
      role: userRole,
    };

    if (userRole === "2") {
      updatedUserData.siret = siret;
      updatedUserData.societe = societe;
      updatedUserData.tva = tva;
    }

    if (currentPassword && newPassword) {
      updatedUserData.current_password = currentPassword;
      updatedUserData.new_password = newPassword;
    }

    try {
      await saveUserData(id, updatedUserData);
      setShowConfirmationModal(false);
      history.push("/users");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        setShowConfirmationModal(false);
        setPasswordError("Le mot de passe courant est incorrect.");
      } else {
        console.error("Error updating user data:", error);
      }
    }
  };

  const handleAddressChange = async (address) => {
    setAddress(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setLatLong(latLng);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  if (!userData) {
    return (
      <Container>
        <p>Chargement...</p>
      </Container>
    );
  }

  return (
    <Container>
      <div className="userList">
        <p className="h6">
          Accueil / Utilisateurs / {userData.prenom} {userData.nom}
        </p>
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">
              {userData.prenom} {userData.nom}
            </h1>
          </Col>
        </Row>
        {loading ? (
          <p className="lead">Chargement...</p>
        ) : (
          <Form>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title className="pb-3">
                  Information personnelle
                </Card.Title>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formCreationDate">
                      <Form.Label>Date de création</Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        disabled
                        value={moment(userData.created_at).format(
                          "DD/MM/YYYY , HH:MM"
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formStatus">
                      <Form.Label>Etat</Form.Label>
                      <Form.Control
                        size="lg"
                        as="select"
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                      >
                        <option value="Active">Active</option>
                        <option value="Inactive">Inactive</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formFirstName">
                      <Form.Label>Prénom</Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Prénom"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formLastName">
                      <Form.Label>Nom</Form.Label>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Nom"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                      <Form.Label>Numéro de téléphone</Form.Label>
                      <Form.Control
                        size="lg"
                        type="tel"
                        placeholder="Numéro de téléphone"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="formEmail">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        size="lg"
                        type="email"
                        placeholder="nom@exemple.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3" controlId="formComment">
                      <Form.Label>Sexe</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        value={userGender}
                        onChange={(e) => setUserGender(e.target.value)}
                      >
                        <option value="" disabled hidden>
                          Sélectionnez le sexe
                        </option>
                        <option value="Homme">Homme</option>
                        <option value="Femme">Femme</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Champs spécifiques pour le rôle 4 */}
            {userRole === "2" && (
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">
                    Informations complémentaires
                  </Card.Title>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formSiret">
                        <Form.Label>Siret</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          placeholder="Siret"
                          value={siret}
                          onChange={(e) => setSiret(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formSociete">
                        <Form.Label>Société</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          placeholder="Société"
                          value={societe}
                          onChange={(e) => setSociete(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formTva">
                        <Form.Label>TVA</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          placeholder="TVA"
                          value={tva}
                          onChange={(e) => setTva(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}

            {/* Adresse */}
            <Card className="mb-3">
              <Card.Body>
                <Card.Title className="pb-3">Adresse</Card.Title>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formAddress">
                      <Form.Label>Adresse</Form.Label>
                      <PlacesAutocomplete
                        value={address}
                        onChange={setAddress}
                        onSelect={handleAddressChange}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <Form.Control
                              {...getInputProps({
                                placeholder: "Entrez votre adresse",
                              })}
                              size="lg"
                            />
                            <div>
                              {loading ? <div>Chargement...</div> : null}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {/* Password */}
            {id == userConnected && (
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">Mot de passe</Card.Title>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mb-3"
                        controlId="formCurrentPassword"
                      >
                        <Form.Label>Mot de passe actuel</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Mot de passe actuel"
                          value={currentPassword}
                          onChange={(e) => {
                            setCurrentPassword(e.target.value);
                            setPasswordError("");
                            setNouveauPasswordError("");
                          }}
                        />
                        {passwordError && (
                          <Alert variant="danger" className="mt-2">
                            {passwordError}
                          </Alert>
                        )}
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formNewPassword">
                        <Form.Label>Nouveau mot de passe</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Nouveau mot de passe"
                          value={newPassword}
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                            setPasswordError("");
                          }}
                        />
                        {nouveauPasswordError && (
                          <Alert variant="danger" className="mt-2">
                            {nouveauPasswordError}
                          </Alert>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            )}

            {/* Boutons d'action */}
            <Row className="mt-4">
              <Col>
                <Button
                  variant="primary"
                  onClick={handleSaveClick}
                  className="me-2"
                >
                  Enregistrer
                </Button>
                <Button variant="secondary" onClick={() => history.goBack()}>
                  Annuler
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
      {/* Confirmation Modal */}
      <ConfirmationModal
        show={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleSaveConfirm}
        actionType={actionType}
      />
      <OkModal
        show={showOkModal}
        onClose={handleOkModalClose}
        onConfirm={handleOkModalClose}
      />
    </Container>
  );
}

export default EditScreen;
