import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const BackButton = ({locationSubCategorie, editPage}) => {

  const history = useHistory();
  const location = useLocation();
  const goBack = () => {
    if (locationSubCategorie ) {
      window.location.reload();
    } else if(editPage) {
      history.goBack();
    }else{
      history.push('/dashboard');
    }
  };

  return (
    <a 
    onClick={goBack}
    className=" p-3 mb-3"
    href='#back'
    >
    <FontAwesomeIcon icon={faArrowLeft} size="3x" color='#0044AB' />
    </a>
  );
};

export default BackButton;
