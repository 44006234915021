//DataService.js
import api from "../../utils/ApiConfig";


//send Reset Code Email
const SendResetCodeEmail = async (email) => {

    try {
      const response = await api.post(`bo/reset`,email);
      //check if the response status is 200(OK)
      if (response.status === 200) {
        return response;
      } else {
        throw new Error(`Failed to reset password:`);
      }
    } catch (error) {
      console.error("Error reset password", error);
      throw error;
    }
  };
//verify Reset Code
const VerifyResetCode = async (email,code) => {
    try {
      const response = await api.post(`verify-reset-code`,email,code);
      //check if the response status is 200(OK)
      if (response.status === 200) {
        return response;
      } else {
        throw new Error(`Failed to virify code:`);
      }
    } catch (error) {
      console.error("Error reset password", error);
      throw error;
    }
  };
//UpdatePassword
const UpdatePassword = async (data) => {
    try {
      const response = await api.post(`update-password`,data);
      //check if the response status is 200(OK)
      if (response.status === 200) {
        return response;
      } else {
        throw new Error(`Failed to update password:`);
      }
    } catch (error) {
      console.error("Error updating password", error);
      throw error;
    }
  };





// Export methods ************************************************************
export { SendResetCodeEmail, VerifyResetCode, UpdatePassword };
