import React, { useEffect, useState } from "react";
import { useTable, useFilters, usePagination, useSortBy } from "react-table";
import arrowUpDown from "../images/arrowUpDown.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { fetchProductData } from "../pages/Proucts/DataService";
import { fetchCodePromoData } from "../pages/CodePromo/DataService";
import { fetchDeliveryTowerData } from "../pages/DeliveryTower/DataService";
import { fetchDeliveriesData } from "../pages/Deliveries/DataService";
import { fetchUserData } from "../pages/users/dataService";
import { fetchCategoriesData } from "../pages/Categories/DataService";
import { fetchBrandsData } from "../pages/Brands/DataService";
import { fetchOrdersData } from "../pages/orders/DataService";
import { Col } from "react-bootstrap";
export default function DynamicTable({
  columns,
  data,
  link,
  setNbr,
  nbr,
  total,
  last_page,
  setSearchTerm,
  searchTerm,
  name,
}) {
  const [tableData, setTableData] = useState(data);
  const [query, setQuery] = useState("");
  const [filterInput, setFilterInput] = useState("");
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setFilter,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state: { pageIndex, pageSize },
    setPageSize,
  } = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  //Efectuer la recherche avec la terme stocker dans query
  const handleFilterChange = async () => {
    let query = "&search_term=";
    setSearchTerm(query + filterInput);
    setNbr(1);
    let data = [];
    if (name === "codePromo") {
      data = await fetchCodePromoData(nbr, query);
    } else if (name === "zoneLivraison") {
      data = await fetchDeliveriesData(nbr, query);
    } else if (name === "product") {
      data = await fetchProductData(nbr, query);
    } else if (name === "user") {
      data = await fetchUserData(nbr, query);
    } else if (name === "categorie") {
      data = await fetchCategoriesData(nbr, query);
    } else if (name === "marque") {
      data = await fetchBrandsData(nbr, query);
    } else if (name === "order") {
      data = await fetchOrdersData(nbr, query);
    }


    // Mettre à jour les données du tableau avec les nouvelles données filtrées
    setTableData(data?.data);
  };
  //changer l'etat de la terme à chercher
  const handleChangeTerm = (event) => {
    const value = event.target.value;
    setQuery(value);
    setFilterInput(value); // Met à jour la valeur de l'input
  };
  const handleChangeTermDate = async (event) => {
    const value = event.target.value;
    setQuery(value);
    setFilterInput(value); // Met à jour la valeur de l'input
    let query = "&search_date=" + value; // Met à jour la valeur de la requête
    setSearchTerm(query);
    setNbr(1);
    let data = await fetchDeliveryTowerData(nbr, query); // Utilise fetchCodePromoData avec le nouveau numéro de page
    setTableData(data?.data);
};
  const renderPageNumbers = () => {
    const maxPageButtons = 5;
    const currentPage = pageIndex + 1;
    const totalPages = pageOptions.length;
    const startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    const endPage = Math.min(startPage + maxPageButtons - 1, totalPages);
    const pageNumbers = [];
    for (let i = 1; i <= link.length; i++) {
      pageNumbers.push(
        <strong
          key={i}
          className={`page-tab ${pageIndex === i - 1 ? "active" : ""}`}
          onClick={() => nextPage(i - 1)}
        >
          {i}
        </strong>
      );
    }
    return pageNumbers;
  };
  useEffect(() => {
    setTableData(data);
  }, [data, nbr]);
  return (
    <div className="dynamic-table-container">
      <div className="pt-2 pb-3">
        <div className="custom-search ">
          {name === "deliveryTower" ? (
         <Col md={6}>
            <input
              type="date" // Définition du type date-local
              id="date"
              name="date"
              className="form-control"
              value={filterInput}
              onChange={handleChangeTermDate}
            />
          </Col>  
          ) : (
            <>
              <input
                type="text"
                className="custom-search-input form-control"
                placeholder="Rechercher"
                value={filterInput}
                onChange={handleChangeTerm}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleFilterChange();
                  }
                }}
              />
              <button
                className="custom-search-botton"
                type="submit"
                onClick={handleFilterChange}
              >
                <FontAwesomeIcon icon={faSearch} size="1x" color="#fff" />
              </button>
            </>
          )}
        </div>
      </div>
      <span className="d-flex align-items-center pb-3">
        {/* <p className="text-large px-2">Afficher</p>{' '}
        <select
          style={{ width: '70px' }}
          className="form-select"
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <p className="text-large px-2">lignes</p> */}
      </span>
      <table {...getTableProps()} className="dynamic-table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <img
                        src={arrowUpDown}
                        alt="Down Arrow"
                        width="15px"
                        style={{ marginLeft: "4px" }}
                      />
                    ) : (
                      <img
                        src={arrowUpDown}
                        alt="Up Arrow"
                        width="15px"
                        style={{ marginLeft: "4px" }}
                      />
                    )
                  ) : (
                    ""
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                style={{
                  backgroundColor: rowIndex % 2 === 0 ? "white" : "#F2F5F8",
                }}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="container mb-5">
        <div className="row">
          <div className="col-7">
            <p className="h6">Total de {total} lignes </p>
          </div>
          <div className="col d-flex justify-content-end align-items-center">
            {/* <button onClick={previousPage} disabled={!canPreviousPage} className="btn btn-outline-primary CustomPagination">Précedent</button> */}
            {/* {renderPageNumbers()} */}
            {/* <button onClick={nextPage} disabled={!canNextPage} className="btn btn-outline-primary CustomPagination">Suivant</button> */}

            {/* Bouton "Précédent" */}
            {nbr > 1 && (
              <strong
                onClick={() => {
                  setNbr(nbr - 1);
                }}
              >
                Précédent
              </strong>
            )}

            {/* Affichage des numéros de page */}
            {Array.from({ length: last_page }, (_, index) => index + 1).map(
              (pageNum) => {
                // Afficher les trois premières pages
                if (pageNum <= 3) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher les trois dernières pages
                else if (pageNum > last_page - 3) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher la page actuelle et les pages voisines
                else if (pageNum >= nbr - 1 && pageNum <= nbr + 1) {
                  return (
                    <strong
                      key={pageNum}
                      className={`page-tab ${nbr === pageNum ? "active" : ""}`}
                      onClick={() => {
                        setNbr(pageNum);
                      }}
                    >
                      {pageNum}
                    </strong>
                  );
                }
                // Afficher des points pour les autres pages
                else if (pageNum === 4 || pageNum === last_page - 3) {
                  return <strong key={pageNum}>...</strong>;
                }
              }
            )}

            {/* Bouton "Suivant" */}
            {nbr < last_page && (
              <strong
                onClick={() => {
                  setNbr(nbr + 1);
                }}
              >
                Suivant
              </strong>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
