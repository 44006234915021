// DashboardService.js
import api from '../../utils/ApiConfig';
// Fetch all KPI from the database
const fetchKpiData = async (periodData) => {
    try {
      const response = await api.get(`/kpi/panier`, { params: periodData });
      if (response.data.error) {
        throw new Error(response.data.error); // Gérer l'erreur en fonction de la structure de réponse
      }
      return response.data;  // retourner les informations
    } catch (error) {
      console.error('Error saving Kpi data:', error);
      throw error;
    }
  }
  
// Export methods ************************************************************
export {fetchKpiData}