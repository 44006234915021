import React, { useState, useRef } from "react";
import { SendResetCodeEmail, VerifyResetCode } from "./DataService";
import { useHistory } from "react-router-dom";

function ResetPasswordScreen() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [afterSend, setAfterSend] = useState(false);
  const [code, setCode] = useState("");
  const [verificationSuccess, setVerificationSuccess] = useState(false);
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const history = useHistory();
  const sendMail = async () => {
    try {
      setError(" ");
      const res = await SendResetCodeEmail({ email });
      console.log(res);
      setAfterSend(true);
    } catch (err) {
      console.log(err);
      setError("Email introuvable");
    }
  };

  function handleInput(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];
    const nextInput = inputRefs[index + 1];

    const newCode = [...code];
    if (/^[a-z]+$/.test(input.value)) {
      const uc = input.value.toUpperCase();
      newCode[index] = uc;
      inputRefs[index].current.value = uc;
    } else {
      newCode[index] = input.value;
    }
    setCode(newCode.join(""));

    input.select();

    if (input.value === "") {
      if (previousInput) {
        previousInput.current.focus();
      }
    } else if (nextInput) {
      nextInput.current.select();
    }
  }

  function handleFocus(e) {
    e.target.select();
  }

  function handleKeyDown(e, index) {
    const input = e.target;
    const previousInput = inputRefs[index - 1];

    if ((e.keyCode === 8 || e.keyCode === 46) && input.value === "") {
      e.preventDefault();
      setCode(
        (prevCode) => prevCode.slice(0, index) + prevCode.slice(index + 1)
      );
      if (previousInput) {
        previousInput.current.focus();
      }
    }
  }

  const handlePaste = (e) => {
    const pastedCode = e.clipboardData.getData("text");
    if (pastedCode.length === 4) {
      setCode(pastedCode);
      inputRefs.forEach((inputRef, index) => {
        inputRef.current.value = pastedCode.charAt(index);
      });
    }
  };

  const handleConfirmCode = async () => {
    try {
      const res = await VerifyResetCode({ email, code });
      if (res?.data?.success) {
        setVerificationSuccess(true);
        history.push("/changer_mot_de_passe", { state: { email, code } });
      }
    } catch (err) {
      console.log(err);
      setError("Le code de confirmation est incorrect. Veuillez réessayer.");
    }
  };

  return (
    <div className="container-fluid vh-100 d-flex align-items-center justify-content-center mainBgColor">
      <div className="container-sm blurBG p-4 rounded" style={{ width: 400 }}>
        {afterSend ? (
          verificationSuccess ? (
            <div className="text-center">
              <h4 className="mainColor">Code vérifié avec succès</h4>
              <p className="mainColor">
                Tu peux maintenant réinitialiser ton mot de passe.
              </p>
            </div>
          ) : (
            <div className="text-center">
              <h5 className="text-left mainColor style-h5">
                Valide ton adresse e-mail en utilisant le code envoyé
              </h5>
              <p className="mainColor text-code-confirm">
                Le code permettant de réinitialiser ton mot de passe a été
                envoyé à {email}. Si tu ne le vois pas vérifie dans tes spams.
              </p>
              <div className="d-flex justify-content-center gap-2 mt-3">
                {[0, 1, 2, 3].map((index) => (
                  <input
                    className="code-input text-2xl bg-gray-800 flex p-2 text-center"
                    key={index}
                    type="text"
                    maxLength={1}
                    onChange={(e) => handleInput(e, index)}
                    ref={inputRefs[index]}
                    autoFocus={index === 0}
                    onFocus={handleFocus}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onPaste={handlePaste}
                  />
                ))}
              </div>
              <div className="text-danger">{error}</div>
              <div className="mt-3">
                <button
                  className="btn btn-primary btn-lg w-100"
                  onClick={handleConfirmCode}
                >
                  Confirmer
                </button>
              </div>
            </div>
          )
        ) : (
          <div className="text-left">
            <h5 className="text-left mainColor style-h5">
              Mot de passe oublié
            </h5>
            <p className="mainColor margin-32 text-code-confirm">
              Confirme ton e-mail et nous t’enverrons un e-mail pour
              réinitialiser ton mot de passe.
            </p>
            <div className="form-floating mb-3">
              <input
                type="email"
                placeholder=""
                className="form-control form-control-lg"
                value={email}
                id="floatingInput"
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="floatingInput" className="code-label">
                Adresse e-mail
              </label>
            </div>
            <div className="text-danger">{error}</div>
            <button className="btn btn-primary btn-lg w-100" onClick={sendMail}>
              Envoyer le code
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default ResetPasswordScreen;
