import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Row,
} from "react-bootstrap";
import ClickBack from "../../components/buttons/ClickBack";
import { createUser } from "./dataService";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

function AddScreen() {
  const history = useHistory(); // Utilisation de useHistory
  const [apiError, setApiError] = useState(null); //  état pour gérer les erreurs de l'API
  //Fonction pour la validation du formulaire d'ajout
  const validationSchema = Yup.object().shape({
    nom: Yup.string().required("Nom est requis"),
    prenom: Yup.string().required("Prénom est requis"),
    telephone: Yup.string().required("Numéro de téléphone est requis"),
    email: Yup.string().email("Email invalide").required("Email est requis"),
    password: Yup.string()
      .required("Mot de passe est requis")
      .matches( 
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
        "Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial."
      ),
    role: Yup.string().required("Role est requis"),
  });
  //Fonction pour ajouter un utilisateur fait appel au createUser
  const handleSaveClick = async (values) => {
    try {
      await createUser(values);
      history.push("/users");
    } catch (error) {
      if (error.response && error.response.status === 422) {
        // Handle 422 error specifically
        if (
          error.response.data &&
          error.response.data.data &&
          error.response.data.data.email
        ) {
          setApiError("L'Email est déja choisit!");
        } else {
          // autre erreur 422 errors
          setApiError("Email validation error");
        }
      }
    }
  };
  return (
    <Container>
      <div className="userList">
        <p className="h6">Accueil / Utilisateurs / Ajouter</p>
        <Row className="pt-4 pb-2">
          <Col style={{ display: "flex", alignItems: "center" }}>
            <ClickBack editPage={true} />
            <h1 className="p-1">Ajouter Utilisateur</h1>
          </Col>
        </Row>
        <Formik
          initialValues={{
            nom: "",
            prenom: "",
            telephone: "",
            email: "",
            password: "",
            role: "",
            employee_commande: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            handleSaveClick(values);
            setSubmitting(false);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Card className="mb-3">
                <Card.Body>
                  <Card.Title className="pb-3">
                    Information personnelle
                  </Card.Title>
                  {apiError && <Alert variant="danger">{apiError}</Alert>}
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Nom</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          placeholder="Nom"
                          name="nom"
                          value={values.nom}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.nom && !!errors.nom}
                        />
                        <ErrorMessage
                          name="nom"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Prénom</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          name="prenom"
                          placeholder="Prenom"
                          value={values.prenom}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.prenom && !!errors.prenom}
                        />
                        <ErrorMessage
                          name="prenom"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formPhoneNumber">
                        <Form.Label>Numéro de téléphone</Form.Label>
                        <Form.Control
                          size="lg"
                          type="tel"
                          placeholder="Numéro de téléphone"
                          name="telephone"
                          value={values.telephone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.telephone && !!errors.telephone}
                        />
                        <ErrorMessage
                          name="telephone"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          size="lg"
                          type="email"
                          placeholder="nom@exemple.com"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.email && !!errors.email}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col
                      md={6}
                      className="password-input-container form-floating mb-3 flex-grow-1"
                    >
                      <Form.Group controlId="passwordRole">
                        <Form.Label>Mot de Passe</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          placeholder="Mot de Passe"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.password && !!errors.password}
                        />
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3" controlId="formRole">
                        <Form.Label>Role</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          className="form-control"
                          value={values.role}
                          name="role"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.role && !!errors.role}
                        >
                          <option value="" disabled hidden>
                            Sélectionnez le Rôle
                          </option>
                          <option value="1">Particulier</option>
                          <option value="3">Livreur</option>
                          <option value="2">Auto-entrepreneur</option>
                          <option value="5">Admin</option>
                        </Form.Select>
                        <ErrorMessage
                          name="role"
                          component="div"
                          className="text-danger"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        className="mt-3 d-flex justify-content-end align-items-center"
                        controlId="formComment"
                      >
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Enregistrer
                        </Button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}

export default AddScreen;
